import { React, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { ShuffleArray, SearchObject } from "../helpers";

import ItemCard from "../components/nestable/ItemCard";
import RefineResults from "../components/nestable/RefineResults";

import { supabase } from "../supabaseClient";
const { REACT_APP_TRADE } = process.env;

function Stock({ trade, fromSearch = false }) {
    const location = useLocation();
    const [allVans, setAllVans] = useState(null);
    const [isFiltered, setIsFiltered] = useState(false);
    const [filteredVans, setFilteredVans] = useState(null);
    const [visibleVans, setVisibleVans] = useState(null);
    const [stockLoaded, setStockLoaded] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    const [itemsPerPage, setItemsPerPage] = useState(20);

    const [selectedMakes, setSelectedMakes] = useState(
        location.state && location.state.selectedMakes
            ? location.state.selectedMakes
            : []
    );
    const [writtenOff, setWrittenOff] = useState(
        location.state && location.state.writtenOff
            ? location.state.writtenOff
            : false
    );

    console.log("selectedMakes = ");
    console.log(selectedMakes);
    console.log("writtenOff = ");
    console.log(writtenOff);

    useEffect(() => {
        supabase
            .from(`stock`)
            .select()
            .range(0, itemsPerPage)
            .then(({ data }) => {
                // setAllVans(
                //     ShuffleArray(data) // Randomise returned vans
                // );

                setAllVans(data);
                setStockLoaded(true);
            });
    }, []);

    useEffect(() => {
        if (isFiltered) {
            setVisibleVans(filteredVans);
        } else {
            setVisibleVans(allVans);
        }
    }, [stockLoaded, isFiltered]);

    const loadMore = () => {
        setPageNumber(pageNumber + 1);
        console.log("loadMore");
        supabase
            .from(`stock`)
            .select()
            .range(
                itemsPerPage * pageNumber,
                itemsPerPage * pageNumber + itemsPerPage
            )
            .then(({ data }) => {
                // setAllVans((allVans) => [...allVans, ShuffleArray(data)]);
                setAllVans((allVans) => [...allVans, data]);
            });
    };

    const clearFilters = (e) => {
        e.preventDefault();
        setIsFiltered(false);
        setSearchTerm("");
    };

    const updateSearchTerm = (e) => {
        setSearchTerm(e.target.value);
    };

    const searchAndOrderFormSubmitted = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const formProps = Object.fromEntries(formData);

        if (formProps["s"] != "") {
            setSearchTerm(formProps["s"]);
            setFilteredVans(SearchObject(allVans, formProps["s"]));
            setIsFiltered(true);
        }
    };

    return (
        <div>
            <section className="pt-36 pb-10 bg-hero-image bg-cover bg-center relative">
                <div className="container mx-auto px-4 md:px-10 lg:px-20 flex">
                    <div className="w-2/5">
                        <p className="uppercase text-white text-xs font-semibold">
                            Our vans
                        </p>
                        <h1 className="uppercase text-white text-5xl font-extrabold pt-3 pb-8">
                            Listings
                        </h1>
                    </div>
                </div>
            </section>

            <section className="bg-grey-light py-16">
                <div className="container mx-auto px-4 md:px-10 lg:px-20">
                    <div className="grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12 gap-4">
                        <div className="col-start-1 lg:col-start-2 col-span-4 md:col-span-2 flex flex-col items-center justify-start gap-4">
                            <div className="w-full">
                                <h2
                                    className={`uppercase text-blue text-base font-extrabold${
                                        !isFiltered ? " pb-2" : ""
                                    }`}
                                >
                                    Refine results
                                </h2>
                                {isFiltered ? (
                                    <button
                                        onClick={clearFilters}
                                        type="button"
                                        className="hover:underline text-blue text-xs font-base pb-2"
                                    >
                                        Clear Filters
                                    </button>
                                ) : (
                                    ""
                                )}

                                <span className="block h-px w-full bg-grey"></span>
                                <RefineResults />
                            </div>
                        </div>
                        <div className="col-span-4 md:col-span-6 lg:col-span-8 flex flex-col items-center justify-start gap-4">
                            <form
                                onSubmit={searchAndOrderFormSubmitted}
                                action=""
                                className="w-full flex flex-col md:flex-row lg:items-center justify-between gap-4"
                            >
                                <input
                                    type="search"
                                    placeholder="search"
                                    autoComplete="off"
                                    autoCorrect="off"
                                    autoCapitalize="off"
                                    spellCheck="false"
                                    value={searchTerm}
                                    onChange={updateSearchTerm}
                                    name="s"
                                    id="s"
                                    className="w-full md:w-4/5 bg-white px-3 rounded h-11 uppercase text-blue placeholder:text-blue text-xs font-semibold leading-none ring-1 ring-opacity-25 ring-blue focus:outline-none focus:ring-1 focus:ring-blue"
                                />
                                <select
                                    name=""
                                    id=""
                                    className="w-6/12 md:w-1/5 rounded px-3 h-11 uppercase text-blue text-xs font-semibold leading-none focus:outline-none focus:ring-1 focus:ring-blue"
                                >
                                    <option value="" default>
                                        Sort by
                                    </option>
                                </select>
                                <button
                                    type="submit"
                                    className="hidden"
                                ></button>
                            </form>

                            <>
                                {!stockLoaded ? (
                                    <>
                                        <p>Loading stock</p>
                                    </>
                                ) : (
                                    <>
                                        {visibleVans &&
                                            visibleVans.map((van) => {
                                                return (
                                                    <ItemCard
                                                        sku={van.sku}
                                                        previewImage={
                                                            van.images[0]
                                                        }
                                                        rrpPrice={
                                                            van.retail_price
                                                        }
                                                        listedPrice={
                                                            trade
                                                                ? van.trade_price
                                                                : van.regular_price
                                                        }
                                                        vehicleName={van.name}
                                                        year={"2000"}
                                                        fuelType={"diesel"}
                                                        expiryDate={
                                                            "2021/10/22"
                                                        }
                                                        mileage={"139836"}
                                                        engineSize={
                                                            van.engine_size
                                                        }
                                                        location={"manchester"}
                                                        colour={van.colour}
                                                        regDate={"2021/10/22"}
                                                    />
                                                );
                                            })}

                                        {/* <button
                                            className="px-4 py-3 bg-red uppercase text-white text-sm font-semibold rounded-md flex items-center w-fit"
                                            onClick={loadMore}
                                        >
                                            Load more
                                        </button> */}
                                    </>
                                )}
                            </>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Stock;
