import React from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
// import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";

// import Home from "../../pages/home";
// import Stock from "../../pages/stock";
// import Van from "../../pages/van";

import { ReactComponent as LogoIcon } from "../../assets/icons/logo-icon.svg";
import { ReactComponent as LogoTradeIcon } from "../../assets/icons/logo-trade-icon.svg";
import { ReactComponent as VanIcon } from "../../assets/icons/van-icon_white-sm.svg";
import { ReactComponent as PlusIcon } from "../../assets/icons/plus-icon_white-sm.svg";
import { ReactComponent as UserIcon } from "../../assets/icons/user-icon_blue-sm.svg";

import { UseAuth } from "../../contexts/auth";

const Navigation = ({ trade }) => {
    const location = useLocation();
    const hideNav = location.pathname == "/" && trade != true;
    const { user, signOut } = UseAuth();

    const navigate = useNavigate();

    async function handleSignOut() {
        // Ends user session
        await signOut();

        // Redirects the user to Login page
        if (trade) {
            navigate("/");
        } else {
            navigate("/home");
        }
    }

    return (
        <header className="w-full absolute top-0 left-0 z-50">
            <div className="container mx-auto px-4 md:px-10 lg:px-20">
                <div className="py-4 md:py-8">
                    <div className="flex items-center justify-between w-full">
                        {trade ? (
                            <a href="/" className="w-24 md:w-32 md:w-auto">
                                <LogoTradeIcon className="w-full" />
                            </a>
                        ) : (
                            <a href="/home" className="w-24 md:w-32 md:w-auto">
                                <LogoIcon className="w-full" />
                            </a>
                        )}

                        {!hideNav ? (
                            <nav className="flex items-center justify-end gap-7">
                                <Link
                                    to="/stock"
                                    className="uppercase text-xs md:text-sm text-white flex items-center"
                                >
                                    <VanIcon className="mr-1 lg:mr-4" />
                                    Stock
                                </Link>

                                {user ? (
                                    <>
                                        <Link
                                            to="/preferences"
                                            className="uppercase text-xs md:text-sm text-white items-center flex"
                                        >
                                            <PlusIcon className="mr-1 lg:mr-4" />
                                            Preferences
                                        </Link>
                                        <button
                                            onClick={handleSignOut}
                                            className="px-4 py-3 bg-white uppercase text-blue text-xs md:text-sm font-semibold rounded-md hidden md:flex items-center"
                                        >
                                            <UserIcon className="mr-1 lg:mr-4" />
                                            Logout
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        {trade ? (
                                            <>
                                                <Link
                                                    to="/signup"
                                                    className="uppercase text-sm text-white items-center hidden md:flex"
                                                >
                                                    <PlusIcon className="mr-1 lg:mr-4" />
                                                    Register
                                                </Link>
                                                <Link
                                                    to="/login"
                                                    className="px-4 py-3 bg-white uppercase text-blue text-sm font-semibold rounded-md flex items-center"
                                                >
                                                    <UserIcon className="mr-1 lg:mr-4" />
                                                    Login
                                                </Link>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </>
                                )}
                            </nav>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Navigation;
