import { useRef, useState } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import { parsePhoneNumber } from "awesome-phonenumber";

import { UseAuth } from "./../contexts/auth";

import { ReactComponent as VanIcon } from "../assets/icons/van-icon_white-sm.svg";
import heroImage from "../assets/images/vans.png";

import { supabase } from "../supabaseClient";

const GraphQLClient = require("graphql-request");

const {
    REACT_APP_MONDAY_API_KEY,
    REACT_APP_MONDAY_API_URL,
    REACT_APP_MONDAY_SEND_TO_TRADERS_BOARD,
} = process.env;

const client = new GraphQLClient.GraphQLClient(REACT_APP_MONDAY_API_URL, {
    headers: {
        "Content-Type": "application/json",
        Authorization: REACT_APP_MONDAY_API_KEY,
    },
});

function Signup({ trade }) {
    const [showOtpScreen, setShowOtpScreen] = useState(false);
    const [userPhoneNumber, setUserPhoneNumber] = useState("");
    const [userFullName, setUserFullName] = useState("");

    const phoneRef = useRef();
    const passwordRef = useRef();
    const fullnameRef = useRef();
    const otpRef = useRef();

    const navigate = useNavigate();

    // Get signUp + verify function from the auth context
    const { signUp, verifyOTP, user } = UseAuth();

    async function handleRegisterSubmit(e) {
        e.preventDefault();

        // Get phone and password input values
        let phone = phoneRef.current.value;
        const password = passwordRef.current.value;
        const full_name = fullnameRef.current.value;

        const ukPhoneNumber = parsePhoneNumber(phone, { regionCode: "GB" });

        if (ukPhoneNumber && ukPhoneNumber.valid) {
            phone = ukPhoneNumber["number"]["e164"];

            let options = {
                data: {
                    full_name: full_name,
                },
            };
            console.log({ phone, password, options });

            // Calls `signUp` function from the context
            const { error } = await signUp({ phone, password }, options);

            if (error) {
                alert("error signing in");
            } else {
                setUserPhoneNumber(phone);
                setUserFullName(full_name);
                setShowOtpScreen(true);
            }
        }
    }

    async function handleOtpSubmit(e) {
        e.preventDefault();

        // Get OTP input values
        let token = otpRef.current.value;

        if (token) {
            // Calls `verifyOTP` function from the context
            let phone = userPhoneNumber;

            const { session, error } = await verifyOTP({ phone, token });

            if (error) {
                alert("error verifying passcode, please try again");
            } else {
                // Redirect user to Dashboard
                // if (trade) {
                //     navigate("/");
                // } else {
                //     navigate("/home");
                // }
                // check if user exists in Monday "Send To Traders" table, if not create one
                // let itemId = "2587170149";
                console.log("session");
                console.log(session);
                await getMondayItemId(userPhoneNumber).then((mondayID) => {
                    if (mondayID) {
                        updateProfileWithMondayID(mondayID, session.user.id);
                    }
                });
            }
        }
    }

    async function updateProfileWithMondayID(mondayID, userID) {
        try {
            const updates = {
                id: userID,
                monday_id: mondayID,
                updated_at: new Date(),
            };

            console.log(
                "updating user " + userID + " with monday_id" + mondayID
            );
            // console.log("updated user with monday_id" + mondayID);

            let { error } = await supabase.from("profiles").upsert(updates);
            if (error) {
                throw error;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async function getMondayItemId(userPhoneNumber) {
        console.log(userPhoneNumber);
        console.log(REACT_APP_MONDAY_SEND_TO_TRADERS_BOARD);
        const getItemQuery = (userPhoneNumber) => `{
                items_by_column_values(limit: 1, board_id:${REACT_APP_MONDAY_SEND_TO_TRADERS_BOARD}, column_id: "phone",column_value: "${userPhoneNumber.slice(
            1
        )}") {
                    id
                    name
                    column_values {
                        title
                        text
                        value
                    }
                }
        }`;

        const queryBoard = async (userPhoneNumber) => {
            return await client.request(getItemQuery(userPhoneNumber));
        };

        const boardData = await queryBoard(userPhoneNumber);

        let userExists = boardData.items_by_column_values.length;
        if (userExists < 1) {
            // Create user + passback reference for supabase
            let crmObject = {
                phone: {
                    phone: `${userPhoneNumber.slice(1)}`,
                    countryShortName: `GB`,
                },
            };

            let newCrmUserMutation = `mutation (
                    $item_name: String!,
                    $group_id: String!,
                    $column_values: JSON!
                )
                {
                    create_item (
                        board_id: ${REACT_APP_MONDAY_SEND_TO_TRADERS_BOARD},
                        item_name: $item_name,
                        group_id: $group_id,
                        column_values: $column_values
                    ) { id }
                }`;

            let vars = {
                group_id: "1639575309_traders_1512",
                item_name: userFullName,
                column_values: JSON.stringify(crmObject),
            };

            let mondayResponse = {};

            await client
                .request(newCrmUserMutation, JSON.stringify(vars))
                .then((data) => {
                    mondayResponse = data;
                    console.log(mondayResponse);
                });
        } else {
            console.log("monday user row");
            console.log(boardData.items_by_column_values);

            return boardData.items_by_column_values[0]["id"];
        }

        // console.log(boardData);

        // if (boardData) {
        //     // return boardData;
        //     // return {
        //     //     ...boardData["boards"][0]["items"][0]["column_values"],
        //     // }
        // } else {
        //     console.log("create new instance");

        // }
    }

    return user ? (
        <Navigate to={trade ? "/" : "/home"} />
    ) : (
        <>
            <section className="pt-36 pb-10 relative">
                <div className="absolute bottom-0 left-0 bg-white h-32 w-full -z-10"></div>
                <div className="container mx-auto px-4 md:px-10 lg:px-20">
                    <div className="w-full flex flex-col sm:flex-row items-start justify-between">
                        <div className="w-full sm:w-6/12 xl:w-5/12 pb-8">
                            <p className="uppercase text-white text-xs font-semibold">
                                We are VanStation
                            </p>
                            <h1 className="uppercase text-white text-3xl md:text-4xl lg:text-5xl font-extrabold pt-3 pb-8">
                                Registration now open!
                            </h1>
                        </div>
                        <div className="w-full sm:w-6/12">
                            <img src={heroImage} width="686" alt="" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-white py-14">
                <div className="container mx-auto px-4 md:px-10 lg:px-20">
                    <div className="lg:flex justify-around items-center">
                        <div className="lg:w-5/12">
                            <h2 className="uppercase text-3xl text-blue font-extrabold">
                                Register with Vanstation to view favourite
                                vehicle details and manage communication
                                preferences so you're always the first to know
                            </h2>
                        </div>
                        <div className="lg:w-4/12 py-14">
                            {!showOtpScreen ? (
                                <form onSubmit={handleRegisterSubmit}>
                                    <label
                                        htmlFor="input-name"
                                        className="block text-blue pt-6"
                                    >
                                        Name
                                    </label>
                                    <input
                                        required
                                        className="w-full"
                                        id="input-name"
                                        type="text"
                                        ref={fullnameRef}
                                    />

                                    <label
                                        htmlFor="input-phone"
                                        className="block text-blue pt-6"
                                    >
                                        Phone Number
                                    </label>
                                    <input
                                        required
                                        className="w-full"
                                        id="input-phone"
                                        type="tel"
                                        ref={phoneRef}
                                    />

                                    <label
                                        htmlFor="input-password"
                                        className="block text-blue pt-6"
                                    >
                                        Password
                                    </label>
                                    <input
                                        required
                                        className="w-full"
                                        id="input-password"
                                        type="password"
                                        ref={passwordRef}
                                    />

                                    <br />
                                    <br />

                                    <button
                                        type="submit"
                                        className="px-4 py-3 bg-red uppercase text-white text-sm font-semibold rounded-md"
                                    >
                                        Sign up
                                    </button>
                                </form>
                            ) : (
                                <form onSubmit={handleOtpSubmit}>
                                    <h2 className="uppercase text-1xl text-red font-extrabold">
                                        Thanks for registering, in order to
                                        verify your details you should have
                                        recieved a passcode to your mobile, you
                                        can enter this below:
                                    </h2>
                                    <label
                                        htmlFor="input-otp"
                                        className="block text-white"
                                    >
                                        One Time Passcode
                                    </label>
                                    <input
                                        id="input-otp"
                                        type="text"
                                        ref={otpRef}
                                    />

                                    <br />
                                    <br />

                                    <button
                                        type="submit"
                                        className="px-4 py-3 bg-red uppercase text-white text-sm font-semibold rounded-md"
                                    >
                                        Submit
                                    </button>
                                </form>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Signup;
