import React from "react"; // Add useState here
import { Swiper, SwiperSlide } from "swiper/react";
import { Thumbs, Autoplay } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/thumbs";
import "swiper/css/autoplay";

const ProductMediaGallery = ({ images }) => {
    // store thumbs swiper instance
    // const [thumbsSwiper, setThumbsSwiper] = useState(null);

    return (
        <div className="col-start-1 lg:col-start-2 col-span-4 md:col-span-2 lg:col-span-5 w-full">
            <Swiper
                loop={true}
                spaceBetween={16}
                autoplay={{
                    delay: 4000,
                    disableOnInteraction: false,
                    // pauseOnMouseEnter: true,
                }}
                thumbs={{}}
                modules={[Thumbs, Autoplay]}
                className="w-full aspect-[4/3] mb-4 bg-blue rounded-xl"
            >
                {images &&
                    images.map((image) => {
                        return (
                            <SwiperSlide>
                                <img
                                    src={image.replace(
                                        "image/upload/",
                                        "image/upload/w_800/"
                                    )}
                                    alt=""
                                    className="max-w-full h-full object-contain object-center"
                                />
                            </SwiperSlide>
                        );
                    })}
            </Swiper>
            <Swiper
                // onSwiper={setThumbsSwiper}
                loop={true}
                spaceBetween={16}
                slidesPerView={3}
                autoplay={{
                    delay: 4000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                }}
                watchSlidesProgress
                modules={[Thumbs, Autoplay]}
            >
                {images &&
                    images.map((image) => {
                        return (
                            <SwiperSlide>
                                <img
                                    src={image.replace(
                                        "image/upload/",
                                        "image/upload/w_300/"
                                    )}
                                    alt=""
                                    className="aspect-square rounded-xl object-cover object-center"
                                />
                            </SwiperSlide>
                        );
                    })}
            </Swiper>
        </div>
    );
};

export default ProductMediaGallery;
