import { useRef, useState, useEffect } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import { parsePhoneNumber } from "awesome-phonenumber";
// import ReactSlider from "react-slider";
import { Range, getTrackBackground } from "react-range";
import { supabase } from "../supabaseClient";

import { UseAuth } from "./../contexts/auth";

import { ReactComponent as VanIcon } from "../assets/icons/van-icon_white-sm.svg";
import heroImage from "../assets/images/vans.png";

const GraphQLClient = require("graphql-request");

const {
    REACT_APP_MONDAY_API_KEY,
    REACT_APP_MONDAY_API_URL,
    REACT_APP_MONDAY_SEND_TO_TRADERS_BOARD,
} = process.env;

const client = new GraphQLClient.GraphQLClient(REACT_APP_MONDAY_API_URL, {
    headers: {
        "Content-Type": "application/json",
        Authorization: REACT_APP_MONDAY_API_KEY,
    },
});

function Preferences({ trade }) {
    const { user } = UseAuth();
    const [userMondayID, setUserMondayID] = useState(null);
    const [crmUserDetails, setCrmUserDetails] = useState({});
    const [mechanicalOptions, setMechanicalOptions] = useState({});
    const [maxMileageOptions, setMaxMileageOptions] = useState([0]);
    const [maxAgeOptions, setMaxAgeOptions] = useState(0);
    const [catOptions, setCatOptions] = useState({});
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        getMondayIdFromSupabase();
        if (userMondayID) {
            getMondayRecordWithId(userMondayID);
        }
        console.log("crmUserDetails = ");
        console.log(crmUserDetails);
    }, [userMondayID]);

    // useEffect(() => {
    //     if (userMondayID) {
    //         getMondayRecordWithId(userMondayID);
    //     }
    // }, [userMondayID]);

    async function getMondayRecordWithId(userMondayID) {
        const getItemQuery = (userMondayID) => `{
            boards(ids:[${REACT_APP_MONDAY_SEND_TO_TRADERS_BOARD}]){
                items(ids: [${userMondayID}]) {
                    id
                    name
                    column_values {
                        title
                        text
                        value
                    }
                }
            }
        }`;

        const queryBoard = async (userMondayID) => {
            return await client.request(getItemQuery(userMondayID));
        };

        const boardData = await queryBoard(userMondayID);

        let userExists = boardData.boards.length;

        let WILL_BUY_MECHANICAL_INDEX = 8;
        let WILL_BUY_MAX_MILEAGE_INDEX = 10;
        let WILL_BUY_MAX_AGE_INDEX = 10;
        let WILL_BUY_CAT_INDEX = 11;

        if (userExists) {
            let crmColumnValues =
                boardData.boards[0]["items"][0]["column_values"];
            setCrmUserDetails(boardData.boards[0]["items"][0]["column_values"]);

            Object.keys(crmColumnValues).map((i) => {
                if (
                    crmColumnValues[i]["title"] &&
                    crmColumnValues[i]["title"] == "Will buy: Mechanical"
                ) {
                    WILL_BUY_MECHANICAL_INDEX = i;
                }

                if (
                    crmColumnValues[i]["title"] &&
                    crmColumnValues[i]["title"] == "Will buy: Max Mileage"
                ) {
                    WILL_BUY_MAX_MILEAGE_INDEX = i;
                }

                if (
                    crmColumnValues[i]["title"] &&
                    crmColumnValues[i]["title"] == "Will buy: Max Age YYYY"
                ) {
                    WILL_BUY_MAX_AGE_INDEX = i;
                }

                if (
                    crmColumnValues[i]["title"] &&
                    crmColumnValues[i]["title"] == "Will Buy CAT"
                ) {
                    WILL_BUY_CAT_INDEX = i;
                }
            });

            setMechanicalOptions(
                crmColumnValues[WILL_BUY_MECHANICAL_INDEX]["text"]
                    ? crmColumnValues[WILL_BUY_MECHANICAL_INDEX]["text"]
                    : ""
            );

            console.log(
                "WILL_BUY_MECHANICAL_INDEX = " + WILL_BUY_MECHANICAL_INDEX
            );
            console.log(
                "WILL_BUY_MAX_MILEAGE_INDEX = " + WILL_BUY_MAX_MILEAGE_INDEX
            );
            console.log("WILL_BUY_MAX_AGE_INDEX = " + WILL_BUY_MAX_AGE_INDEX);

            console.log("::");
            console.log(crmColumnValues[WILL_BUY_MAX_MILEAGE_INDEX]["value"]);
            setMaxMileageOptions(
                crmColumnValues[WILL_BUY_MAX_MILEAGE_INDEX]["value"] &&
                    Number(
                        crmColumnValues[WILL_BUY_MAX_MILEAGE_INDEX][
                            "value"
                        ].replace(/['"]+/g, "")
                    ) < 250001
                    ? [
                          Number(
                              crmColumnValues[WILL_BUY_MAX_MILEAGE_INDEX][
                                  "value"
                              ].replace(/['"]+/g, "")
                          ),
                      ]
                    : [250000]
            );

            setMaxAgeOptions(
                crmColumnValues[9]["value"] &&
                    Number(
                        crmColumnValues[WILL_BUY_MAX_AGE_INDEX][
                            "value"
                        ].replace(/['"]+/g, "")
                    ) < 26
                    ? [
                          Number(
                              crmColumnValues[WILL_BUY_MAX_AGE_INDEX][
                                  "value"
                              ].replace(/['"]+/g, "")
                          ),
                      ]
                    : [10]
            );

            setCatOptions(
                crmColumnValues[WILL_BUY_CAT_INDEX]["text"]
                    ? crmColumnValues[WILL_BUY_CAT_INDEX]["text"]
                    : "Will Buy CAT"
            );

            setLoading(false);
        } else {
            console.log("!");
        }
    }

    async function getMondayIdFromSupabase() {
        try {
            let { data, error, status } = await supabase
                .from("profiles")
                .select("monday_id")
                .eq("id", user.id)
                .single();

            if (error && status !== 406) {
                throw error;
            }

            if (data) {
                setUserMondayID(data.monday_id);
            }
        } catch (error) {
            alert(error.message);
        }
    }

    async function updateMechanical(e) {
        setMechanicalOptions(e.target.value);
    }
    async function updateCat(e) {
        setCatOptions(e.target.value);
    }
    async function handleSubmit(e) {
        e.preventDefault();
        console.log("update monday! " + userMondayID);
        console.log("catOptions");
        console.log(catOptions);
        console.log("maxAgeOptions");
        console.log(maxAgeOptions);
        setSaving(true);
        let mondayResponse;
        await client
            .request(
                `mutation (
                $item_id: Int!,
                $column_values: JSON!
            )
            {
                change_multiple_column_values (
                    board_id: ${REACT_APP_MONDAY_SEND_TO_TRADERS_BOARD},
                    item_id: $item_id,
                    column_values: $column_values
                ) { id }
            }`,
                JSON.stringify({
                    item_id: parseInt(userMondayID),
                    column_values: JSON.stringify({
                        status47: {
                            label: mechanicalOptions,
                        },
                        numbers9: maxMileageOptions[0],
                        numbers1: maxAgeOptions[0],
                        dup__of_will_buy__age: {
                            label: catOptions,
                        },
                    }),
                })
            )
            .then((data) => {
                mondayResponse = data;
            });

        if (mondayResponse) {
        } else {
            alert("There was an error saving your preferences");
        }
        setSaving(false);
    }

    return !user ? (
        <Navigate to={trade ? "/" : "/home"} />
    ) : (
        <>
            {!loading ? (
                <>
                    <section className="pt-36 pb-10 relative">
                        <div className="absolute bottom-0 left-0 bg-white h-32 w-full -z-10"></div>
                        <div className="container mx-auto px-4 md:px-10 lg:px-20">
                            <div className="w-full flex flex-col sm:flex-row items-start justify-between">
                                <div className="w-full sm:w-6/12 xl:w-5/12 pb-8">
                                    <p className="uppercase text-white text-xs font-semibold">
                                        Welcome back{" "}
                                        {user.user_metadata.full_name}
                                    </p>
                                    <h1 className="uppercase text-white text-3xl md:text-4xl lg:text-5xl font-extrabold pt-3 pb-8">
                                        Manage your preferences
                                    </h1>
                                </div>
                                <div className="w-full sm:w-6/12">
                                    <img src={heroImage} width="686" alt="" />
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="bg-white py-14">
                        <div className="container mx-auto px-4 md:px-10 lg:px-20">
                            <form onSubmit={handleSubmit}>
                                <p className="text-xl uppercase font-semibold">
                                    Specify which categories of vehicles you
                                    wish to be notified about by changing the
                                    details below
                                </p>
                                <div className="py-10">
                                    <p className="text-lg uppercase font-semibold">
                                        Mechanical issues:
                                    </p>
                                    <label
                                        className="block cursor-pointer"
                                        htmlFor="Good, Minor, Major, Non Runner"
                                    >
                                        <input
                                            type="radio"
                                            className="mr-2"
                                            name="mechanical"
                                            id="Good, Minor, Major, Non Runner"
                                            value="Good, Minor, Major, Non Runner"
                                            onChange={updateMechanical}
                                            checked={
                                                mechanicalOptions ===
                                                "Good, Minor, Major, Non-Runner"
                                            }
                                        />
                                        Good, Minor, Major, Non Runner
                                    </label>
                                    <label
                                        className="block cursor-pointer"
                                        htmlFor="Good, Minor, Major"
                                    >
                                        <input
                                            type="radio"
                                            className="mr-2"
                                            id="Good, Minor, Major"
                                            value="Good, Minor, Major"
                                            name="mechanical"
                                            onChange={updateMechanical}
                                            checked={
                                                mechanicalOptions ===
                                                "Good, Minor, Major"
                                            }
                                        />
                                        Good, Minor, Major
                                    </label>
                                    <label
                                        className="block cursor-pointer"
                                        htmlFor="Good, Minor"
                                    >
                                        <input
                                            type="radio"
                                            className="mr-2"
                                            id="Good, Minor"
                                            value="Good, Minor"
                                            name="mechanical"
                                            onChange={updateMechanical}
                                            checked={
                                                mechanicalOptions ===
                                                "Good, Minor"
                                            }
                                        />
                                        Good, Minor
                                    </label>
                                    <label
                                        className="block cursor-pointer"
                                        htmlFor="Good"
                                    >
                                        <input
                                            type="radio"
                                            className="mr-2"
                                            id="Good"
                                            value="Good"
                                            name="mechanical"
                                            onChange={updateMechanical}
                                            checked={
                                                mechanicalOptions === "Good"
                                            }
                                        />
                                        Good
                                    </label>
                                </div>

                                <div className="py-10 lg:w-6/12">
                                    <p className="text-lg uppercase font-semibold pb-10">
                                        Max mileage:
                                    </p>

                                    <Range
                                        values={maxMileageOptions}
                                        step={1000}
                                        min={1000}
                                        max={250000}
                                        onChange={(values) => {
                                            setMaxMileageOptions(values);
                                        }}
                                        renderTrack={({ props, children }) => (
                                            <div
                                                onMouseDown={props.onMouseDown}
                                                onTouchStart={
                                                    props.onTouchStart
                                                }
                                                style={{
                                                    ...props.style,
                                                    height: "0px",
                                                    display: "flex",
                                                    width: "100%",
                                                    outline: "none",
                                                }}
                                            >
                                                <div
                                                    ref={props.ref}
                                                    style={{
                                                        height: "10px",
                                                        width: "100%",
                                                        borderRadius: "12px",
                                                        background:
                                                            getTrackBackground({
                                                                values: maxMileageOptions,
                                                                colors: [
                                                                    "#72d7f4",
                                                                    "#ededed",
                                                                ],
                                                                min: 1000,
                                                                max: 250000,
                                                            }),
                                                        alignSelf: "center",
                                                        outline: "none",
                                                    }}
                                                >
                                                    {children}
                                                </div>
                                            </div>
                                        )}
                                        renderThumb={({ props, isDragged }) => (
                                            <div
                                                {...props}
                                                style={{
                                                    ...props.style,
                                                    height: "22px",
                                                    width: "24px",
                                                    borderRadius: "5px",
                                                    backgroundColor: "#72d7f4",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    boxShadow:
                                                        "0px 2px 6px rgba(0, 0, 0, 0.2)",
                                                    outline: "none",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        height: "12px",
                                                        width: "14px",
                                                        borderRadius: "2px",
                                                        backgroundColor:
                                                            isDragged
                                                                ? "#ffffff"
                                                                : "#ffffff",
                                                        outline: "none",
                                                    }}
                                                />
                                                <div
                                                    style={{
                                                        backgroundColor:
                                                            "#72d7f4",
                                                        color: "#ffffff",
                                                        fontSize: "14px",
                                                        lineHeight: "1",
                                                        padding:
                                                            "8px 8px 6px 8px",
                                                        borderRadius: "6px",
                                                        position: "absolute",
                                                        bottom: "-32px",
                                                        outline: "none",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent:
                                                            "center",
                                                        fontFamily:
                                                            "neuzeit-grotesk, sans-serif",
                                                    }}
                                                >
                                                    {maxMileageOptions[0]}
                                                    &nbsp;miles
                                                </div>
                                            </div>
                                        )}
                                    />
                                </div>

                                <div className="py-10 lg:w-6/12">
                                    <p className="text-lg uppercase font-semibold pb-10">
                                        Max age (in number of years):
                                    </p>

                                    <Range
                                        values={maxAgeOptions}
                                        step={1}
                                        min={1}
                                        max={25}
                                        onChange={(values) => {
                                            setMaxAgeOptions(values);
                                        }}
                                        renderTrack={({ props, children }) => (
                                            <div
                                                onMouseDown={props.onMouseDown}
                                                onTouchStart={
                                                    props.onTouchStart
                                                }
                                                style={{
                                                    ...props.style,
                                                    height: "0px",
                                                    display: "flex",
                                                    width: "100%",
                                                    outline: "none",
                                                }}
                                            >
                                                <div
                                                    ref={props.ref}
                                                    style={{
                                                        height: "10px",
                                                        width: "100%",
                                                        borderRadius: "12px",
                                                        background:
                                                            getTrackBackground({
                                                                values: maxAgeOptions,
                                                                colors: [
                                                                    "#72d7f4",
                                                                    "#ededed",
                                                                ],
                                                                min: 1,
                                                                max: 25,
                                                            }),
                                                        alignSelf: "center",
                                                        outline: "none",
                                                    }}
                                                >
                                                    {children}
                                                </div>
                                            </div>
                                        )}
                                        renderThumb={({ props, isDragged }) => (
                                            <div
                                                {...props}
                                                style={{
                                                    ...props.style,
                                                    height: "22px",
                                                    width: "24px",
                                                    borderRadius: "5px",
                                                    backgroundColor: "#72d7f4",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    boxShadow:
                                                        "0px 2px 6px rgba(0, 0, 0, 0.2)",
                                                    outline: "none",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        height: "12px",
                                                        width: "14px",
                                                        borderRadius: "2px",
                                                        backgroundColor:
                                                            isDragged
                                                                ? "#ffffff"
                                                                : "#ffffff",
                                                        outline: "none",
                                                    }}
                                                />
                                                <div
                                                    style={{
                                                        backgroundColor:
                                                            "#72d7f4",
                                                        color: "#ffffff",
                                                        fontSize: "14px",
                                                        lineHeight: "1",
                                                        padding:
                                                            "8px 8px 6px 8px",
                                                        borderRadius: "6px",
                                                        position: "absolute",
                                                        bottom: "-32px",
                                                        outline: "none",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        whiteSpace: "nowrap",
                                                        justifyContent:
                                                            "center",
                                                        fontFamily:
                                                            "neuzeit-grotesk, sans-serif",
                                                    }}
                                                >
                                                    {maxAgeOptions[0]}
                                                    &nbsp;years old
                                                </div>
                                            </div>
                                        )}
                                    />
                                </div>

                                <div className="py-10">
                                    <p className="text-lg uppercase font-semibold">
                                        Are you interested in CAT vechicles:
                                    </p>

                                    <label
                                        className="block cursor-pointer"
                                        htmlFor="Will Buy CAT"
                                    >
                                        <input
                                            type="radio"
                                            className="mr-2"
                                            name="cat"
                                            id="Will Buy CAT"
                                            value="Will Buy CAT"
                                            onChange={updateCat}
                                            checked={
                                                catOptions === "Will Buy CAT"
                                            }
                                        />
                                        Yes
                                    </label>
                                    <label
                                        className="block cursor-pointer"
                                        htmlFor="Will Not Buy CAT"
                                    >
                                        <input
                                            type="radio"
                                            className="mr-2"
                                            id="Will Not Buy CAT"
                                            value="Will Not Buy CAT"
                                            name="cat"
                                            onChange={updateCat}
                                            checked={
                                                catOptions ===
                                                "Will Not Buy CAT"
                                            }
                                        />
                                        No
                                    </label>
                                </div>

                                <button
                                    type="submit"
                                    disabled={saving}
                                    className="px-10 py-3 bg-red uppercase text-white text-sm font-semibold rounded-md flex items-center"
                                >
                                    {saving ? "Saving" : "Save"}
                                </button>
                            </form>
                        </div>
                    </section>
                </>
            ) : (
                ""
            )}
        </>
    );
}

export default Preferences;
