import React from "react";

import ItemCard from "../components/nestable/ItemCard";

import HomepageFilterSearch from "../components/nestable/HomepageFilterSearch";
import { ReactComponent as VanOutline } from "../assets/icons/van-icon_outline.svg";
import { ReactComponent as HeartOutline } from "../assets/icons/heart-icon_outline.svg";
import { ReactComponent as SpeechOutline } from "../assets/icons/speech-icon_outline.svg";
import { ReactComponent as PlusIcon } from "../assets/icons/plus-icon_white-sm.svg";
import { ReactComponent as VanIcon } from "../assets/icons/van-icon_white-sm.svg";

import heroImage from "../assets/images/vans.png";

function Home() {
    //
    return (
        <div>
            <section className="pt-36 pb-10 relative">
                <div className="container mx-auto px-4 md:px-10 lg:px-20">
                    <div className="w-full flex flex-col sm:flex-row items-start justify-between">
                        <div className="w-full sm:w-6/12 xl:w-5/12 pb-8">
                            <p className="uppercase text-white text-xs font-semibold">
                                We are VanStation
                            </p>
                            <h1 className="uppercase text-white text-3xl md:text-4xl lg:text-5xl font-extrabold pt-3 pb-8">
                                BUY Vans online, simply & quickly
                            </h1>
                            <a
                                href="/home"
                                className="px-4 py-3 bg-red uppercase text-white text-sm font-semibold rounded-md inline-flex mr-4 items-center w-fit"
                            >
                                <VanIcon className="mr-1" />
                                Public
                            </a>

                            <a
                                href="//staging.trade.vanstation.co.uk"
                                className="px-4 py-3 bg-red uppercase text-white text-sm font-semibold rounded-md inline-flex items-center w-fit"
                            >
                                <VanIcon className="mr-1" />
                                Trade
                            </a>
                        </div>
                        <div className="w-full sm:w-6/12">
                            <img src={heroImage} width="686" alt="" />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Home;
