import React from "react";
import ReactWhatsapp from "react-whatsapp";

import { ReactComponent as LikeIcon } from "../../assets/icons/like-icon_white-sm.svg";
import { ReactComponent as BasketIcon } from "../../assets/icons/basket-icon_white-sm.svg";

import { UseAuth } from "../../contexts/auth";
const { REACT_APP_TRADE } = process.env;

const ProductInfoPanel = ({ vanData }) => {
    const { user } = UseAuth();

    console.log("vanData");
    console.log(vanData);

    const [mot_expiry_year, mot_expiry_month, mot_expiry_day] =
        vanData.mot_expiry.split("-");
    return (
        <div className="col-start-1 lg:col-start-7 col-span-4 md:col-span-2 lg:col-span-5 p-5 bg-white rounded-xl flex flex-col justify-between gap-6">
            {vanData.description ? (
                <div>
                    <h3 className="uppercase text-base text-blue font-extrabold mb-3">
                        Listing Description
                    </h3>
                    <hr className="border-grey" />
                    <p className="text-xs text-blue font-light leading-5 mt-6">
                        {vanData.description}
                    </p>
                </div>
            ) : (
                ""
            )}

            <div>
                <h3 className="uppercase text-base text-blue font-extrabold mb-3">
                    Vehicle Information
                </h3>
                <hr className="border-grey" />
                <div className="mt-6 flex flex-wrap items-start justify-between gap-y-4">
                    <div className="uppercase text-xs text-blue w-4/12">
                        <p className="font-medium opacity-40 pb-1">
                            Mot expiry date
                        </p>
                        <p className="font-bold">
                            {mot_expiry_day}/{mot_expiry_month}/
                            {mot_expiry_year}
                        </p>
                    </div>
                    <div className="uppercase text-xs text-blue w-6/12 lg:w-4/12">
                        <p className="font-medium opacity-40 pb-1">
                            Engine size
                        </p>
                        <p className="font-bold">{vanData.engine_size}</p>
                    </div>
                    <div className="uppercase text-xs text-blue w-6/12 lg:w-4/12">
                        <p className="font-medium opacity-40 pb-1">Colour</p>
                        <p className="font-bold">{vanData.colour}</p>
                    </div>
                    <div className="uppercase text-xs text-blue w-6/12 lg:w-4/12">
                        <p className="font-medium opacity-40 pb-1">Mileage</p>
                        <p className="font-bold">{vanData.mileage}</p>
                    </div>
                    {/* <div className="uppercase text-xs text-blue w-6/12 lg:w-4/12">
                        <p className="font-medium opacity-40 pb-1">Location</p>
                        <p className="font-bold">Manchester</p>
                    </div> */}
                    <div className="uppercase text-xs text-blue w-6/12 lg:w-4/12">
                        <p className="font-medium opacity-40 pb-1">
                            Date of reg
                        </p>
                        <p className="font-bold">{vanData.first_use_date}</p>
                    </div>
                    {/* <div className="uppercase text-xs text-blue w-12/12">
                        <p className="font-medium opacity-40 pb-1">Features</p>
                        <p className="font-bold">2021/10/22</p>
                    </div> */}
                </div>
            </div>
            <div className="opacity-25">
                <h3 className="uppercase text-base text-blue font-extrabold mb-3">
                    Sale and Location Information
                </h3>
                <hr className="border-grey" />
                <div className="mt-6 flex flex-wrap items-start justify-between gap-y-4">
                    <div className="uppercase text-xs text-blue w-6/12 lg:w-3/12">
                        <p className="font-medium opacity-40 pb-1">Location</p>
                        <p className="font-bold">Manchester</p>
                    </div>
                    <div className="uppercase text-xs text-blue w-6/12 lg:w-3/12">
                        <p className="font-medium opacity-40 pb-1">Vendor</p>
                        <p className="font-bold">WBBV</p>
                    </div>
                    <div className="uppercase text-xs text-blue w-6/12 lg:w-3/12">
                        <p className="font-medium opacity-40 pb-1">Listed</p>
                        <p className="font-bold">22/02/2022</p>
                    </div>
                    <div className="uppercase text-xs text-blue w-6/12 lg:w-3/12">
                        <p className="font-medium opacity-40 pb-1">Last Sale</p>
                        <p className="font-bold">11/01/2022</p>
                    </div>
                </div>
            </div>
            <div>
                <h2 className="text-green text-3xl lg:text-5xl font-extrabold">
                    {REACT_APP_TRADE == "true" ? (
                        <>
                            {vanData.trade_price != "£NaN"
                                ? vanData.trade_price
                                : ""}
                        </>
                    ) : (
                        <>
                            {vanData.regular_price != "£NaN"
                                ? vanData.regular_price
                                : ""}
                        </>
                    )}
                </h2>
                <div className="flex items-start gap-4 pt-4">
                    <button className="px-4 py-3 bg-red uppercase text-white text-sm font-semibold rounded-md flex items-center w-fit">
                        <LikeIcon className="mr-1" />
                        {user ? <>Save</> : <>Login to save this van</>}
                    </button>
                    <ReactWhatsapp
                        className="px-4 py-3 bg-green uppercase text-white text-sm font-semibold rounded-md flex items-center w-fit"
                        number={
                            REACT_APP_TRADE == "true"
                                ? "+447401199751"
                                : "+447360268883"
                        }
                        message={`VS TEST ---> I am interested in this ${
                            vanData.name
                        } https://${
                            REACT_APP_TRADE == "true"
                                ? "staging.trade"
                                : "staging"
                        }.vanstation.co.uk/van/${vanData.sku}`}
                    >
                        <BasketIcon className="mr-1" />
                        Enquire
                    </ReactWhatsapp>
                </div>
            </div>
        </div>
    );
};

export default ProductInfoPanel;
