import { React, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { ShuffleArray } from "../helpers";
import ItemCard from "../components/nestable/ItemCard";

import { UseAuth } from "./../contexts/auth";

import HomepageFilterSearch from "../components/nestable/HomepageFilterSearch";
import { ReactComponent as VanOutline } from "../assets/icons/van-icon_outline.svg";
import { ReactComponent as HeartOutline } from "../assets/icons/heart-icon_outline.svg";
import { ReactComponent as SpeechOutline } from "../assets/icons/speech-icon_outline.svg";
import { ReactComponent as PlusIcon } from "../assets/icons/plus-icon_white-sm.svg";
import { ReactComponent as VanIcon } from "../assets/icons/van-icon_white-sm.svg";

import heroImage from "../assets/images/vans.png";

import { supabase } from "../supabaseClient";

const { REACT_APP_TRADE } = process.env;

function Home() {
    const [latestVans, setLatestVans] = useState(null);
    const { user } = UseAuth();

    useEffect(() => {
        supabase
            .from(`stock`)
            .select()
            .limit(8)
            .then(({ data }) => {
                setLatestVans(
                    ShuffleArray(data) // Randomise returned vans
                );
            });
    }, []);

    return (
        <div>
            <section className="pt-36 pb-10 relative">
                <div
                    className={`absolute bottom-0 left-0 bg-${
                        user || REACT_APP_TRADE != "true" ? "grey" : "white"
                    } h-32 w-full -z-10`}
                ></div>
                <div className="container mx-auto px-4 md:px-10 lg:px-20">
                    <div className="w-full flex flex-col sm:flex-row items-start justify-between">
                        <div className="w-full sm:w-6/12 xl:w-5/12 pb-8">
                            <p className="uppercase text-white text-xs font-semibold">
                                We are VanStation
                            </p>
                            <h1 className="uppercase text-white text-3xl md:text-4xl lg:text-5xl font-extrabold pt-3 pb-8">
                                BUY Vans online, simply & quickly
                            </h1>
                            <a
                                href="/stock"
                                className="px-4 py-3 bg-red uppercase text-white text-sm font-semibold rounded-md flex items-center w-fit"
                            >
                                <VanIcon className="mr-1" />
                                View all vans
                            </a>
                        </div>
                        <div className="w-full sm:w-6/12">
                            <img src={heroImage} width="686" alt="" />
                        </div>
                    </div>
                </div>
            </section>

            {user || REACT_APP_TRADE != "true" ? (
                <></>
            ) : (
                <section className="bg-white py-14">
                    <div className="container mx-auto px-4 md:px-10 lg:px-20">
                        <h2 className="uppercase text-4xl text-blue text-center font-extrabold">
                            Sign up today for added{" "}
                            <span className="brush-underline relative">
                                benefits
                            </span>
                        </h2>
                        <div className="grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12 gap-4">
                            <div className="col-start-2 md:col-start-1 lg:col-start-2 xl:col-start-3 col-span-2 md:col-span-8 lg:col-span-10 xl:col-span-8 flex flex-col md:flex-row items-center justify-between gap-y-12 py-14">
                                <article className="flex flex-col items-center justify-center gap-4 w-52">
                                    <VanOutline />
                                    <h3 className="uppercase text-base text-blue text-center font-extrabold">
                                        View van <br /> information in detail
                                    </h3>
                                    <p className="text-xs text-blue text-center font-light">
                                        Sed ut perspiciatis unde omnis iste
                                        natus error sit voluptatem
                                    </p>
                                </article>
                                <article className="flex flex-col items-center justify-center gap-4 w-52">
                                    <HeartOutline />
                                    <h3 className="uppercase text-base text-blue text-center font-extrabold">
                                        Save vans <br /> for later
                                    </h3>
                                    <p className="text-xs text-blue text-center font-light">
                                        Sed ut perspiciatis unde omnis iste
                                        natus error sit voluptatem
                                    </p>
                                </article>
                                <article className="flex flex-col items-center justify-center gap-4 w-52">
                                    <SpeechOutline />
                                    <h3 className="uppercase text-base text-blue text-center font-extrabold">
                                        Enquire for <br /> more information
                                    </h3>
                                    <p className="text-xs text-blue text-center font-light">
                                        Sed ut perspiciatis unde omnis iste
                                        natus error sit voluptatem
                                    </p>
                                </article>
                            </div>
                        </div>
                        <Link
                            to="/signup"
                            className="px-4 py-3 bg-red uppercase text-white text-sm font-semibold rounded-md flex items-center w-fit mx-auto"
                        >
                            <PlusIcon className="mr-1" />
                            Register now
                        </Link>
                    </div>
                </section>
            )}

            <section className="bg-grey py-20">
                <div className="container mx-auto px-4 md:px-10 lg:px-20">
                    <div>
                        <p className="uppercase text-xs to-blue text-center font-semibold pb-3">
                            Latest vans
                        </p>
                        <h2 className="uppercase text-5xl text-blue text-center font-extrabold">
                            Find a van today
                        </h2>
                    </div>
                    <div className="grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12 gap-4 py-11">
                        <div className="lg:col-start-3 col-span-4 lg:col-span-8 flex flex-col items-center justify-start gap-4">
                            {latestVans &&
                                latestVans.slice(0, 2).map((van) => {
                                    return (
                                        <ItemCard
                                            sku={van.sku}
                                            previewImage={van.images[0]}
                                            rrpPrice={van.retail_price}
                                            listedPrice={van.regular_price}
                                            vehicleName={van.name}
                                            year={"2000"}
                                            fuelType={"diesel"}
                                            expiryDate={"2021/10/22"}
                                            mileage={"139836"}
                                            engineSize={van.engine_size}
                                            location={"manchester"}
                                            colour={van.colour}
                                            regDate={"2021/10/22"}
                                        />
                                    );
                                })}
                        </div>
                    </div>
                    <a
                        href="/stock"
                        className="px-4 py-3 bg-red uppercase text-white text-sm font-semibold rounded-md flex items-center w-fit mx-auto"
                    >
                        <VanIcon className="mr-1" />
                        View all vans
                    </a>
                </div>
            </section>

            <section className="bg-white py-20">
                <div className="container mx-auto px-4 md:px-10 lg:px-20">
                    <div className="grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12 gap-4">
                        <HomepageFilterSearch />
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Home;
