import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { parsePhoneNumber } from "awesome-phonenumber";

import { UseAuth } from "./../contexts/auth";

import { ReactComponent as VanIcon } from "../assets/icons/van-icon_white-sm.svg";
import heroImage from "../assets/images/vans.png";

function Login({ trade }) {
    const phoneRef = useRef();
    const passwordRef = useRef();

    const navigate = useNavigate();

    // Get signUp function from the auth context
    const { signIn } = UseAuth();

    async function handleSubmit(e) {
        e.preventDefault();

        // Get phone and password input values
        let phone = phoneRef.current.value;
        const password = passwordRef.current.value;

        const ukPhoneNumber = parsePhoneNumber(phone, { regionCode: "GB" });

        if (ukPhoneNumber && ukPhoneNumber.valid) {
            phone = ukPhoneNumber["number"]["e164"];
            // Calls `signIn` function from the context
            const { error } = await signIn({ phone, password });

            if (error) {
                alert("error signing in");
            } else {
                // Redirect user to Dashboard
                if (trade) {
                    navigate("/");
                } else {
                    navigate("/home");
                }
            }
        }
    }

    return (
        <>
            <section className="pt-36 pb-10 relative">
                <div className="absolute bottom-0 left-0 bg-white h-32 w-full -z-10"></div>
                <div className="container mx-auto px-4 md:px-10 lg:px-20">
                    <div className="w-full flex flex-col sm:flex-row items-start justify-between">
                        <div className="w-full sm:w-6/12 xl:w-5/12 pb-8">
                            <p className="uppercase text-white text-xs font-semibold mb-4">
                                Login
                            </p>
                            <form onSubmit={handleSubmit}>
                                <label
                                    htmlFor="input-phone"
                                    className="block text-white"
                                >
                                    Mobile Number
                                </label>
                                <input
                                    id="input-phone"
                                    type="tel"
                                    ref={phoneRef}
                                />

                                <label
                                    htmlFor="input-password"
                                    className="block text-white mt-4"
                                >
                                    Password
                                </label>
                                <input
                                    id="input-password"
                                    type="password"
                                    ref={passwordRef}
                                />

                                <br />

                                <button
                                    type="submit"
                                    className="px-4 py-3 mt-4 bg-white uppercase text-red text-sm font-semibold rounded-md"
                                >
                                    Login
                                </button>

                                <p>
                                    Forgot password? <a>Click here to reset</a>
                                </p>
                            </form>
                        </div>
                        <div className="w-full sm:w-6/12">
                            <img src={heroImage} width="686" alt="" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Login;
