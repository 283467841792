import React from "react";

const ServiceHistoryPanel = () => {
    return (
        <div className="opacity-25 col-start-1 lg:col-start-2 col-span-4 lg:col-span-5 p-5 bg-white rounded-xl">
            <article className="">
                <h3 className="uppercase text-base text-blue font-extrabold mb-3">
                    Service History
                </h3>
                <hr className="border-grey" />
                <div className="mt-6 flex flex-wrap items-start justify-between gap-y-4">
                    <div className="uppercase text-xs text-blue w-4/12">
                        <p className="font-medium opacity-40 pb-1">
                            Registered keepers
                        </p>
                        <p className="font-bold">3</p>
                    </div>
                    <div className="uppercase text-xs text-blue w-6/12 lg:w-4/12">
                        <p className="font-medium opacity-40 pb-1">
                            No. of services
                        </p>
                        <p className="font-bold">2</p>
                    </div>
                    <div className="uppercase text-xs text-blue w-6/12 lg:w-4/12">
                        <p className="font-medium opacity-40 pb-1">
                            Date of last service
                        </p>
                        <p className="font-bold">20/09/2017</p>
                    </div>
                    <div className="uppercase text-xs text-blue w-6/12 lg:w-4/12">
                        <p className="font-medium opacity-40 pb-1">Odometer</p>
                        <p className="font-bold">57658</p>
                    </div>
                    <div className="uppercase text-xs text-blue w-6/12 lg:w-4/12">
                        <p className="font-medium opacity-40 pb-1">
                            MOT expiry
                        </p>
                        <p className="font-bold">21/05/2022</p>
                    </div>
                    <div className="uppercase text-xs text-blue w-6/12 lg:w-4/12">
                        <p className="font-medium opacity-40 pb-1">
                            Inspection report
                        </p>
                        <a
                            href="/"
                            className="font-bold text-xs text-red underline"
                        >
                            View
                        </a>
                    </div>
                </div>
            </article>
        </div>
    );
};

export default ServiceHistoryPanel;
