import { React, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ReactComponent as LikeIcon } from "../assets/icons/like-icon_white-sm.svg";
import { ReactComponent as BasketIcon } from "../assets/icons/basket-icon_white-sm.svg";
import ProductMediaGallery from "../components/nestable/ProductMediaGallery";
import ProductInfoPanel from "../components/nestable/ProductInfoPanel";
import ProductGuaranteePanel from "../components/nestable/ProductGuaranteePanel";
import ServiceHistoryPanel from "../components/nestable/ServiceHistoryPanel";
import InspectionHistoryPanel from "../components/nestable/InspectionHistoryPanel";
import ReactWhatsapp from "react-whatsapp";

import { supabase } from "../supabaseClient";

import { UseAuth } from "../contexts/auth";

function Van({ trade }) {
    console.log("trade = " + trade);
    const { itemId } = useParams();
    const { user } = UseAuth();

    const [vanLoaded, setVanLoaded] = useState(false);
    const [vanData, setVanData] = useState(null);

    useEffect(() => {
        supabase
            .from(`stock`)
            .select()
            .filter("sku", "in", "(" + itemId + ")")
            .then(({ data }) => {
                setVanLoaded(true);
                setVanData(data[0]);
                console.log(data[0]);
            });
    }, []);

    return (
        <>
            {!vanLoaded ? (
                <></>
            ) : (
                <div>
                    <section className="pt-36 pb-10 bg-hero-image bg-cover bg-center relative">
                        <div className="container mx-auto px-4 md:px-10 lg:px-20">
                            <div className="lg:grid lg:grid-cols-12 lg:gap-4">
                                <div className="lg:col-start-2 lg:col-span-10 flex flex-col md:flex-row items-start lg:items-center justify-between gap-4">
                                    <div>
                                        <p className="uppercase text-green text-3xl md:text-4xl lg:text-5xl font-extrabold">
                                            {trade ? (
                                                <>
                                                    {vanData.trade_price !=
                                                    "£NaN"
                                                        ? vanData.trade_price
                                                        : ""}
                                                </>
                                            ) : (
                                                <>
                                                    {vanData.regular_price !=
                                                    "£NaN"
                                                        ? vanData.regular_price
                                                        : ""}
                                                </>
                                            )}
                                        </p>
                                        <h1 className="uppercase text-white text-lg md:text-2xl font-extrabold pt-0 md:pt-2 lg:pt-3">
                                            {vanData.name}
                                        </h1>
                                    </div>
                                    <div className="flex items-center gap-4">
                                        <button className="px-4 py-3 bg-red uppercase text-white text-sm font-semibold rounded-md flex items-center w-fit">
                                            <LikeIcon className="mr-1" />
                                            {user ? (
                                                <>Save</>
                                            ) : (
                                                <>Login to save this van</>
                                            )}
                                        </button>
                                        <ReactWhatsapp
                                            className="px-4 py-3 bg-green uppercase text-white text-sm font-semibold rounded-md flex items-center w-fit"
                                            number={
                                                trade
                                                    ? "+447401199751"
                                                    : "+447360268883"
                                            }
                                            message={`VS TEST ---> I am interested in this ${
                                                vanData.name
                                            } https://${
                                                trade
                                                    ? "staging.trade"
                                                    : "staging"
                                            }.vanstation.co.uk/van/${
                                                vanData.sku
                                            }`}
                                        >
                                            <BasketIcon className="mr-1" />
                                            Enquire
                                        </ReactWhatsapp>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="bg-white py-6">
                        <div className="container mx-auto px-4 md:px-10 lg:px-20">
                            <div className="grid grid-cols-4 lg:grid-cols-12 gap-4">
                                <ProductMediaGallery images={vanData.images} />
                                <ProductInfoPanel vanData={vanData} />
                            </div>
                        </div>
                    </section>

                    <section className="bg-grey pt-6 pb-12">
                        <div className="container mx-auto px-4 md:px-10 lg:px-20">
                            <div className="grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12 gap-4">
                                <ServiceHistoryPanel />
                                <ProductGuaranteePanel />
                                {/* <InspectionHistoryPanel /> */}
                            </div>
                        </div>
                    </section>
                </div>
            )}
        </>
    );
}

export default Van;
