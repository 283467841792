import React from "react";

import { ReactComponent as LogoIcon } from "../../assets/icons/logo-icon.svg";
import { ReactComponent as LinkedinIcon } from "../../assets/icons/linkedin-icon.svg";
import { ReactComponent as TwitterIcon } from "../../assets/icons/twitter-icon.svg";
import { ReactComponent as FaceBookIcon } from "../../assets/icons/facebook-icon.svg";

import ReactWhatsapp from "react-whatsapp";

const { REACT_APP_TRADE } = process.env;

const Footer = () => {
    return (
        <footer>
            <div className="bg-red">
                <div className="container mx-auto px-4 md:px-10 lg:px-20 py-16 flex flex-col items-center justify-center">
                    <h2 className="uppercase text-2xl md:text-4xl lg:text-5xl text-white text-center font-extrabold pb-8">
                        Have any questions?
                    </h2>
                    <ReactWhatsapp
                        className="px-4 py-3 bg-white uppercase text-red text-sm font-semibold rounded-md"
                        number={
                            REACT_APP_TRADE == "true"
                                ? "+447401199751"
                                : "+447360268883"
                        }
                        message={`I've got a question about Vanstation`}
                    >
                        Get in touch via Whatsapp
                    </ReactWhatsapp>
                </div>
            </div>
            <div className="bg-blue">
                <div className="container mx-auto px-4 md:px-10 lg:px-20 py-11">
                    <div className="flex flex-col sm:flex-row items-start sm:items-end justify-between gap-4 pb-12">
                        <LogoIcon />
                        <div className="flex items-center justify-end gap-6">
                            <a
                                href="https://twitter.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <TwitterIcon />
                            </a>
                            <a
                                href="https://www.linkedin.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <LinkedinIcon />
                            </a>
                            <a
                                href="https://www.facebook.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FaceBookIcon />
                            </a>
                        </div>
                    </div>
                    <div className="flex items-center justify-between">
                        <p className="text-xs text-white font-semibold">
                            &copy;{new Date().getFullYear()} Vanstation
                        </p>
                        {/* <a
                            href="/"
                            className="text-xs text-white font-semibold underline"
                        >
                            Terms & Conditions
                        </a> */}
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
