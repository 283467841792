import React from "react";

import { ReactComponent as GuaranteeIcon } from "../../assets/icons/guarantee-icon.svg";

const ProductGuaranteePanel = () => {
    return (
        <article className="col-start-1 md:col-start-5 lg:col-start-7 col-span-4 lg:col-span-5 p-5 bg-white rounded-xl">
            <h3 className="uppercase text-base text-blue font-extrabold mb-3">
                Description
            </h3>
            <hr className="border-grey" />
            <div className="flex lg:flex-row items-start justify-between mt-5">
                <GuaranteeIcon />
                <p className="text-xs text-blue font-light w-10/12 xl:w-11/12">
                    TRADE OR PUBLIC Description FROM MONDAY!
                </p>
            </div>
        </article>
    );
};

export default ProductGuaranteePanel;
