import React from "react";

import { ReactComponent as LikeIcon } from "../../assets/icons/like-icon_white-sm.svg";
import { ReactComponent as BasketIcon } from "../../assets/icons/basket-icon_white-sm.svg";

import ReactWhatsapp from "react-whatsapp";

import { UseAuth } from "../../contexts/auth";
const { REACT_APP_TRADE } = process.env;

const ItemCard = ({
    sku,
    previewImage,
    vehicleName,
    year,
    fuelType,
    rrpPrice,
    listedPrice,
    expiryDate,
    mileage,
    engineSize,
    location,
    colour,
    regDate,
}) => {
    const { user } = UseAuth();
    // console.log(rrpPrice);

    return (
        <div className="relative w-full">
            <a
                href={`/van/${sku}`}
                className="w-full p-3 pb-14 md:pb-0 md:pr-10 block bg-white rounded-lg border border-white hover:border-blue hover:drop-shadow-md transition duration-300"
            >
                <div className="w-full h-full flex flex-col lg:flex-row items-center justify-between gap-4 lg:gap-0">
                    <div className="flex flex-col md:flex-row items-start gap-4 w-6/12">
                        {previewImage ? (
                            <img
                                src={previewImage.replace(
                                    "image/upload/",
                                    "image/upload/w_300/"
                                )}
                                alt=""
                                className="aspect-[4/3] w-full md:w-1/2 lg:w-40 object-cover rounded-lg bg-gradient-to-tr from-red to-blue"
                            />
                        ) : (
                            ""
                        )}

                        <div>
                            <div className="flex items-center justify-start gap-3">
                                <span className="uppercase block text-blue text-xs font-medium border border-blue rounded px-3 py-1 w-fit">
                                    {year}
                                </span>
                                <span className="uppercase block text-red text-xs font-medium border border-red rounded px-3 py-1 w-fit">
                                    {fuelType}
                                </span>
                            </div>
                            <div className="pt-2">
                                <p className="uppercase text-base text-blue font-extrabold">
                                    {vehicleName}
                                </p>
                                <div className="font-light">
                                    {rrpPrice &&
                                    rrpPrice != "£0" &&
                                    rrpPrice != "£NaN" ? (
                                        <>
                                            <span className="text-red font-medium">
                                                {rrpPrice} RRP
                                            </span>{" "}
                                            |{" "}
                                        </>
                                    ) : (
                                        ""
                                    )}
                                    <span className="text-green font-medium">
                                        {listedPrice &&
                                        listedPrice != "£0" &&
                                        listedPrice != "£NaN"
                                            ? listedPrice
                                            : ""}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <span className="block h-px lg:h-full w-full lg:w-px bg-grey"></span>
                    <div className="w-full lg:w-auto flex flex-row lg:flex-col justify-between h-full">
                        <div className="w-6/12 lg:w-auto uppercase text-xs text-blue">
                            <p className="font-medium opacity-40 pb-1">
                                Mot expiry date
                            </p>
                            <p className="font-bold">{expiryDate}</p>
                        </div>
                        <div className="w-6/12 lg:w-auto uppercase text-xs text-blue">
                            <p className="font-medium opacity-40 pb-1">
                                Mileage
                            </p>
                            <p className="font-bold">{mileage}</p>
                        </div>
                    </div>
                    <div className="w-full lg:w-auto flex flex-row lg:flex-col justify-between h-full">
                        <div className="w-6/12 lg:w-auto uppercase text-xs text-blue">
                            <p className="font-medium opacity-40 pb-1">
                                Engine size
                            </p>
                            <p className="font-bold">{engineSize}</p>
                        </div>
                        <div className="w-6/12 lg:w-auto uppercase text-xs text-blue">
                            <p className="font-medium opacity-40 pb-1">
                                Location
                            </p>
                            <p className="font-bold">{location}</p>
                        </div>
                    </div>
                    <div className="w-full lg:w-auto flex flex-row lg:flex-col justify-between h-full">
                        <div className="w-6/12 lg:w-auto uppercase text-xs text-blue">
                            <p className="font-medium opacity-40 pb-1">
                                Colour
                            </p>
                            <p className="font-bold">{colour}</p>
                        </div>
                        <div className="w-6/12 lg:w-auto uppercase text-xs text-blue">
                            <p className="font-medium opacity-40 pb-1">
                                Date of reg
                            </p>
                            <p className="font-bold">{regDate}</p>
                        </div>
                    </div>
                </div>
            </a>
            <div className="absolute right-4 lg:top-4 left-4 lg:left-auto bottom-4 z-40 flex flex-row lg:flex-col items-center justify-between">
                <button
                    className={`bg-red w-7 h-7 flex items-center justify-center rounded-full${
                        user ? "" : " opacity-25"
                    }`}
                >
                    <LikeIcon />
                </button>

                <ReactWhatsapp
                    className="bg-green w-7 h-7 flex items-center justify-center rounded-full"
                    number={
                        REACT_APP_TRADE == "true"
                            ? "+447401199751"
                            : "+447360268883"
                    }
                    message={`VS TEST ---> I am interested in this ${vehicleName} https://${
                        REACT_APP_TRADE == "true" ? "staging.trade" : "staging"
                    }.vanstation.co.uk/van/${sku}`}
                >
                    <BasketIcon />
                </ReactWhatsapp>
            </div>
        </div>
    );
};

export default ItemCard;
