import React from "react";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    useLocation,
} from "react-router-dom";

import Landing from "./pages/landing";
import Home from "./pages/home";
import Stock from "./pages/stock";
import Van from "./pages/van";
import Login from "./pages/login";
import UpdatePassword from "./pages/updatepassword";
import Signup from "./pages/signup";
import Preferences from "./pages/preferences";

import Navigation from "./components/global/Navigation";
import Footer from "./components/global/Footer";

import { AuthProvider } from "./contexts/auth";

import "./App.css";

const { REACT_APP_TRADE } = process.env;

function App() {
    return (
        <Router>
            <AuthProvider>
                <Navigation trade={REACT_APP_TRADE === "true" ? true : false} />
                <div>
                    <Routes>
                        <Route
                            exact
                            path="/"
                            element={
                                REACT_APP_TRADE === "true" ? (
                                    <Home />
                                ) : (
                                    <Landing />
                                )
                            }
                        />
                        <Route
                            exact
                            path="/home"
                            element={
                                <Home
                                    trade={
                                        REACT_APP_TRADE === "true"
                                            ? true
                                            : false
                                    }
                                />
                            }
                        />
                        <Route
                            exact
                            path="/stock"
                            element={
                                <Stock
                                    trade={
                                        REACT_APP_TRADE === "true"
                                            ? true
                                            : false
                                    }
                                />
                            }
                        />
                        <Route
                            exact
                            path="/van/:itemId"
                            element={
                                <Van
                                    trade={
                                        REACT_APP_TRADE === "true"
                                            ? true
                                            : false
                                    }
                                />
                            }
                        />
                        <Route
                            exact
                            path="/login"
                            element={
                                <Login
                                    trade={
                                        REACT_APP_TRADE === "true"
                                            ? true
                                            : false
                                    }
                                />
                            }
                        />
                        <Route
                            exact
                            path="/update-password"
                            element={
                                <UpdatePassword
                                    trade={
                                        REACT_APP_TRADE === "true"
                                            ? true
                                            : false
                                    }
                                />
                            }
                        />
                        <Route
                            exact
                            path="/signup"
                            element={
                                <Signup
                                    trade={
                                        REACT_APP_TRADE === "true"
                                            ? true
                                            : false
                                    }
                                />
                            }
                        />
                        <Route
                            exact
                            path="/preferences"
                            element={
                                <Preferences
                                    trade={
                                        REACT_APP_TRADE === "true"
                                            ? true
                                            : false
                                    }
                                />
                            }
                        />
                    </Routes>
                </div>
                <Footer />
            </AuthProvider>
        </Router>
    );
}

export default App;
