import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as MenuArrow } from "../../assets/icons/select-arrow.svg";

const HomepageFilterSearch = () => {
    const navigate = useNavigate();
    const [showMake, setShowMake] = useState(false);
    const [showMileage, setShowMileage] = useState(false);
    const [showAge, setShowAge] = useState(false);
    const [showQuality, setShowQuality] = useState(false);

    const [selectedMakes, setSelectedMakes] = useState([]);
    const [writtenOff, setWrittenOff] = useState(false);

    const toggleMake = () => {
        setShowMake(!showMake);
    };

    const toggleMileage = () => {
        setShowMileage(!showMileage);
    };

    const toggleAge = () => {
        setShowAge(!showAge);
    };

    const toggleQuality = () => {
        setShowQuality(!showQuality);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        navigate("/stock", {
            state: {
                fromSearch: true,
                selectedMakes: selectedMakes,
                writtenOff: writtenOff,
            },
        });
    };

    const handleWrittenOffChange = (e) => {
        const target = e.target;
        setWrittenOff(target.checked);
    };

    const handleCheckboxChange = (e) => {
        // const target = e.target;
        // console.log(e.nativeEvent.target.value);
        // const value =
        //     target.type === "checkbox" ? target.checked : target.value;
        // const name = target.name;

        // this.setState({
        //     [name]: value,
        // });

        setSelectedMakes([...selectedMakes, e.nativeEvent.target.value]);
    };

    return (
        <div className="lg:col-start-2 col-span-4 lg:col-span-10 bg-grey py-14 px-7 lg:px-28 rounded">
            <div>
                <p className="uppercase text-xs to-blue text-center font-semibold pb-3">
                    Refine your search
                </p>
                <h2 className="uppercase text-5xl text-blue text-center font-extrabold">
                    Search vans
                </h2>
            </div>
            <form
                onSubmit={handleSubmit}
                action="/stock"
                method="POST"
                className="pt-11"
            >
                <article className="flex flex-col items-center">
                    <div className="w-full grid grid-cols-2 lg:grid-cols-6 gap-4 pb-10">
                        <div className="col-span-2 relative w-full bg-white px-3 rounded h-11 uppercase text-blue placeholder:text-blue text-xs font-semibold leading-none flex items-center justify-between">
                            <label htmlFor="written-off" className="w-full">
                                Written Off
                            </label>
                            <input
                                type="checkbox"
                                name="write-off"
                                id="written-off"
                                onChange={handleWrittenOffChange}
                                className="opacity-0 absolute right-3 h-4 w-4"
                            />
                            <div className="rounded border border-grey h-4 w-4 bg-white checked:bg-red flex items-center justify-center">
                                <svg
                                    className="hidden"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="10.763"
                                    height="8.5"
                                    viewBox="0 0 10.763 8.5"
                                >
                                    <path
                                        d="M1051.851-12983l2.969,2.969,6.379-6.379"
                                        transform="translate(-1051.144 12987.117)"
                                        fill="none"
                                        stroke="#18b573"
                                        strokeWidth="2"
                                    />
                                </svg>
                            </div>
                        </div>
                        <div className="relative col-span-2">
                            <div
                                onClick={toggleMake}
                                className={`flex items-center justify-between text-left w-full bg-white px-4 h-11 uppercase text-blue placeholder:text-blue text-xs font-semibold leading-none ${
                                    showMake
                                        ? "rounded-t border-b border-grey"
                                        : "rounded border-b-0 border-grey"
                                }`}
                            >
                                Make
                                <MenuArrow
                                    className={` ${
                                        showMake ? "rotate-180" : "rotate-0"
                                    }`}
                                />
                            </div>
                            <div
                                style={{ display: showMake ? "block" : "none" }}
                                className="px-4 py-2 bg-white rounded-b"
                            >
                                <div className="flex flex-col">
                                    <div className="flex items-center justify-between py-2">
                                        <label
                                            htmlFor="ford"
                                            className="relative text-xs text-blue uppercase font-medium w-full selection:bg-transparent"
                                        >
                                            <span>Ford</span>
                                        </label>
                                        <input
                                            type="checkbox"
                                            name="make"
                                            id="ford"
                                            value="ford"
                                            onChange={handleCheckboxChange}
                                            className="opacity-0 absolute right-4 h-4 w-4"
                                        />
                                        <div className="rounded border border-grey h-4 w-4 bg-white checked:bg-red flex items-center justify-center">
                                            <svg
                                                className="hidden"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="10.763"
                                                height="8.5"
                                                viewBox="0 0 10.763 8.5"
                                            >
                                                <path
                                                    d="M1051.851-12983l2.969,2.969,6.379-6.379"
                                                    transform="translate(-1051.144 12987.117)"
                                                    fill="none"
                                                    stroke="#18b573"
                                                    strokeWidth="2"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between py-2">
                                        <label
                                            htmlFor="merc"
                                            className="relative text-xs text-blue uppercase font-medium w-full"
                                        >
                                            <span>Mercedes</span>
                                        </label>
                                        <input
                                            type="checkbox"
                                            name="make"
                                            id="merc"
                                            value="Mercedes"
                                            onChange={handleCheckboxChange}
                                            className="opacity-0 absolute right-4 h-4 w-4"
                                        />
                                        <div className="rounded border border-grey h-4 w-4 bg-white checked:bg-red flex items-center justify-center">
                                            <svg
                                                className="hidden"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="10.763"
                                                height="8.5"
                                                viewBox="0 0 10.763 8.5"
                                            >
                                                <path
                                                    d="M1051.851-12983l2.969,2.969,6.379-6.379"
                                                    transform="translate(-1051.144 12987.117)"
                                                    fill="none"
                                                    stroke="#18b573"
                                                    strokeWidth="2"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between py-2">
                                        <label
                                            htmlFor="vw"
                                            className="relative text-xs text-blue uppercase font-medium w-full"
                                        >
                                            <span>VW</span>
                                        </label>
                                        <input
                                            type="checkbox"
                                            name="make"
                                            id="vw"
                                            value="VW"
                                            onChange={handleCheckboxChange}
                                            className="opacity-0 absolute right-4 h-4 w-4"
                                        />
                                        <div className="rounded border border-grey h-4 w-4 bg-white checked:bg-red flex items-center justify-center">
                                            <svg
                                                className="hidden"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="10.763"
                                                height="8.5"
                                                viewBox="0 0 10.763 8.5"
                                            >
                                                <path
                                                    d="M1051.851-12983l2.969,2.969,6.379-6.379"
                                                    transform="translate(-1051.144 12987.117)"
                                                    fill="none"
                                                    stroke="#18b573"
                                                    strokeWidth="2"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="relative col-span-2">
                            <div
                                onClick={toggleMileage}
                                className={`flex items-center justify-between text-left w-full bg-white px-4 h-11 uppercase text-blue placeholder:text-blue text-xs font-semibold leading-none ${
                                    showMileage
                                        ? "rounded-t border-b border-grey"
                                        : "rounded border-b-0 border-grey"
                                }`}
                            >
                                Mileage
                                <MenuArrow
                                    className={` ${
                                        showMileage ? "rotate-180" : "rotate-0"
                                    }`}
                                />
                            </div>
                            <div
                                style={{
                                    display: showMileage ? "block" : "none",
                                }}
                                className="px-4 py-2 bg-white rounded-b"
                            >
                                <div className="flex flex-col">
                                    <div className="flex items-center justify-between py-2">
                                        <label
                                            htmlFor="250k"
                                            className="relative text-xs text-blue uppercase font-medium w-full"
                                        >
                                            <span>250k+</span>
                                        </label>
                                        <input
                                            type="checkbox"
                                            name="mileage"
                                            id="250k"
                                            className="opacity-0 absolute right-4 h-4 w-4"
                                        />
                                        <div className="rounded border border-grey h-4 w-4 bg-white checked:bg-red flex items-center justify-center">
                                            <svg
                                                className="hidden"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="10.763"
                                                height="8.5"
                                                viewBox="0 0 10.763 8.5"
                                            >
                                                <path
                                                    d="M1051.851-12983l2.969,2.969,6.379-6.379"
                                                    transform="translate(-1051.144 12987.117)"
                                                    fill="none"
                                                    stroke="#18b573"
                                                    strokeWidth="2"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between py-2">
                                        <label
                                            htmlFor="150k"
                                            className="relative text-xs text-blue uppercase font-medium w-full"
                                        >
                                            <span>150k+</span>
                                        </label>
                                        <input
                                            type="checkbox"
                                            name="mileage"
                                            id="150k"
                                            className="opacity-0 absolute right-4 h-4 w-4"
                                        />
                                        <div className="rounded border border-grey h-4 w-4 bg-white checked:bg-red flex items-center justify-center">
                                            <svg
                                                className="hidden"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="10.763"
                                                height="8.5"
                                                viewBox="0 0 10.763 8.5"
                                            >
                                                <path
                                                    d="M1051.851-12983l2.969,2.969,6.379-6.379"
                                                    transform="translate(-1051.144 12987.117)"
                                                    fill="none"
                                                    stroke="#18b573"
                                                    strokeWidth="2"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between py-2">
                                        <label
                                            htmlFor="100k"
                                            className="relative text-xs text-blue uppercase font-medium w-full"
                                        >
                                            <span>100k+</span>
                                        </label>
                                        <input
                                            type="checkbox"
                                            name="mileage"
                                            id="100k"
                                            className="opacity-0 absolute right-4 h-4 w-4"
                                        />
                                        <div className="rounded border border-grey h-4 w-4 bg-white checked:bg-red flex items-center justify-center">
                                            <svg
                                                className="hidden"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="10.763"
                                                height="8.5"
                                                viewBox="0 0 10.763 8.5"
                                            >
                                                <path
                                                    d="M1051.851-12983l2.969,2.969,6.379-6.379"
                                                    transform="translate(-1051.144 12987.117)"
                                                    fill="none"
                                                    stroke="#18b573"
                                                    strokeWidth="2"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between py-2">
                                        <label
                                            htmlFor="50k"
                                            className="relative text-xs text-blue uppercase font-medium w-full"
                                        >
                                            <span>50k+</span>
                                        </label>
                                        <input
                                            type="checkbox"
                                            name="mileage"
                                            id="50k"
                                            className="opacity-0 absolute right-4 h-4 w-4"
                                        />
                                        <div className="rounded border border-grey h-4 w-4 bg-white checked:bg-red flex items-center justify-center">
                                            <svg
                                                className="hidden"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="10.763"
                                                height="8.5"
                                                viewBox="0 0 10.763 8.5"
                                            >
                                                <path
                                                    d="M1051.851-12983l2.969,2.969,6.379-6.379"
                                                    transform="translate(-1051.144 12987.117)"
                                                    fill="none"
                                                    stroke="#18b573"
                                                    strokeWidth="2"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="relative col-span-2">
                            <div
                                onClick={toggleAge}
                                className={`flex items-center justify-between text-left w-full bg-white px-4 h-11 uppercase text-blue placeholder:text-blue text-xs font-semibold leading-none ${
                                    showAge
                                        ? "rounded-t border-b border-grey"
                                        : "rounded border-b-0 border-grey"
                                }`}
                            >
                                Age
                                <MenuArrow
                                    className={` ${
                                        showAge ? "rotate-180" : "rotate-0"
                                    }`}
                                />
                            </div>
                            <div
                                style={{ display: showAge ? "block" : "none" }}
                                className="px-4 py-2 bg-white rounded-b"
                            >
                                <div className="flex flex-col">
                                    <div className="flex items-center justify-between py-2">
                                        <label
                                            htmlFor="new"
                                            className="relative text-xs text-blue uppercase font-medium w-full"
                                        >
                                            <span>New</span>
                                        </label>
                                        <input
                                            type="checkbox"
                                            name="age"
                                            id="new"
                                            className="opacity-0 absolute right-4 h-4 w-4"
                                        />
                                        <div className="rounded border border-grey h-4 w-4 bg-white checked:bg-red flex items-center justify-center">
                                            <svg
                                                className="hidden"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="10.763"
                                                height="8.5"
                                                viewBox="0 0 10.763 8.5"
                                            >
                                                <path
                                                    d="M1051.851-12983l2.969,2.969,6.379-6.379"
                                                    transform="translate(-1051.144 12987.117)"
                                                    fill="none"
                                                    stroke="#18b573"
                                                    strokeWidth="2"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between py-2">
                                        <label
                                            htmlFor="5year"
                                            className="relative text-xs text-blue uppercase font-medium w-full"
                                        >
                                            <span>5+</span>
                                        </label>
                                        <input
                                            type="checkbox"
                                            name="age"
                                            id="5year"
                                            className="opacity-0 absolute right-4 h-4 w-4"
                                        />
                                        <div className="rounded border border-grey h-4 w-4 bg-white checked:bg-red flex items-center justify-center">
                                            <svg
                                                className="hidden"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="10.763"
                                                height="8.5"
                                                viewBox="0 0 10.763 8.5"
                                            >
                                                <path
                                                    d="M1051.851-12983l2.969,2.969,6.379-6.379"
                                                    transform="translate(-1051.144 12987.117)"
                                                    fill="none"
                                                    stroke="#18b573"
                                                    strokeWidth="2"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between py-2">
                                        <label
                                            htmlFor="10year"
                                            className="relative text-xs text-blue uppercase font-medium w-full"
                                        >
                                            <span>10+</span>
                                        </label>
                                        <input
                                            type="checkbox"
                                            name="age"
                                            id="10year"
                                            className="opacity-0 absolute right-4 h-4 w-4"
                                        />
                                        <div className="rounded border border-grey h-4 w-4 bg-white checked:bg-red flex items-center justify-center">
                                            <svg
                                                className="hidden"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="10.763"
                                                height="8.5"
                                                viewBox="0 0 10.763 8.5"
                                            >
                                                <path
                                                    d="M1051.851-12983l2.969,2.969,6.379-6.379"
                                                    transform="translate(-1051.144 12987.117)"
                                                    fill="none"
                                                    stroke="#18b573"
                                                    strokeWidth="2"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between py-2">
                                        <label
                                            htmlFor="15year"
                                            className="relative text-xs text-blue uppercase font-medium w-full"
                                        >
                                            <span>15+</span>
                                        </label>
                                        <input
                                            type="checkbox"
                                            name="age"
                                            id="15year"
                                            className="opacity-0 absolute right-4 h-4 w-4"
                                        />
                                        <div className="rounded border border-grey h-4 w-4 bg-white checked:bg-red flex items-center justify-center">
                                            <svg
                                                className="hidden"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="10.763"
                                                height="8.5"
                                                viewBox="0 0 10.763 8.5"
                                            >
                                                <path
                                                    d="M1051.851-12983l2.969,2.969,6.379-6.379"
                                                    transform="translate(-1051.144 12987.117)"
                                                    fill="none"
                                                    stroke="#18b573"
                                                    strokeWidth="2"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="relative col-span-2">
                            <div
                                onClick={toggleQuality}
                                className={`flex items-center justify-between text-left w-full bg-white px-4 h-11 uppercase text-blue placeholder:text-blue text-xs font-semibold leading-none ${
                                    showQuality
                                        ? "rounded-t border-b border-grey"
                                        : "rounded border-b-0 border-grey"
                                }`}
                            >
                                Quality
                                <MenuArrow
                                    className={` ${
                                        showQuality ? "rotate-180" : "rotate-0"
                                    }`}
                                />
                            </div>
                            <div
                                style={{
                                    display: showQuality ? "block" : "none",
                                }}
                                className="px-4 py-2 bg-white rounded-b"
                            >
                                <div className="flex flex-col">
                                    <div className="flex items-center justify-between py-2">
                                        <label
                                            htmlFor="good"
                                            className="relative text-xs text-blue uppercase font-medium w-full"
                                        >
                                            <span>Good</span>
                                        </label>
                                        <input
                                            type="checkbox"
                                            name="quality"
                                            id="good"
                                            className="opacity-0 absolute right-4 h-4 w-4"
                                        />
                                        <div className="rounded border border-grey h-4 w-4 bg-white checked:bg-red flex items-center justify-center">
                                            <svg
                                                className="hidden"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="10.763"
                                                height="8.5"
                                                viewBox="0 0 10.763 8.5"
                                            >
                                                <path
                                                    d="M1051.851-12983l2.969,2.969,6.379-6.379"
                                                    transform="translate(-1051.144 12987.117)"
                                                    fill="none"
                                                    stroke="#18b573"
                                                    strokeWidth="2"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between py-2">
                                        <label
                                            htmlFor="minorProb"
                                            className="relative text-xs text-blue uppercase font-medium w-full"
                                        >
                                            <span>Runs with minor</span>
                                        </label>
                                        <input
                                            type="checkbox"
                                            name="quality"
                                            id="minorProb"
                                            className="opacity-0 absolute right-4 h-4 w-4"
                                        />
                                        <div className="rounded border border-grey h-4 w-4 bg-white checked:bg-red flex items-center justify-center">
                                            <svg
                                                className="hidden"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="10.763"
                                                height="8.5"
                                                viewBox="0 0 10.763 8.5"
                                            >
                                                <path
                                                    d="M1051.851-12983l2.969,2.969,6.379-6.379"
                                                    transform="translate(-1051.144 12987.117)"
                                                    fill="none"
                                                    stroke="#18b573"
                                                    strokeWidth="2"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between py-2">
                                        <label
                                            htmlFor="majorProb"
                                            className="relative text-xs text-blue uppercase font-medium w-full"
                                        >
                                            <span>Runs with major</span>
                                        </label>
                                        <input
                                            type="checkbox"
                                            name="quality"
                                            id="majorProb"
                                            className="opacity-0 absolute right-4 h-4 w-4"
                                        />
                                        <div className="rounded border border-grey h-4 w-4 bg-white checked:bg-red flex items-center justify-center">
                                            <svg
                                                className="hidden"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="10.763"
                                                height="8.5"
                                                viewBox="0 0 10.763 8.5"
                                            >
                                                <path
                                                    d="M1051.851-12983l2.969,2.969,6.379-6.379"
                                                    transform="translate(-1051.144 12987.117)"
                                                    fill="none"
                                                    stroke="#18b573"
                                                    strokeWidth="2"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between py-2">
                                        <label
                                            htmlFor="nonRunner"
                                            className="relative text-xs text-blue uppercase font-medium w-full"
                                        >
                                            <span>Non-runner</span>
                                        </label>
                                        <input
                                            type="checkbox"
                                            name="quality"
                                            id="nonRunner"
                                            className="opacity-0 absolute right-4 h-4 w-4"
                                        />
                                        <div className="rounded border border-grey h-4 w-4 bg-white checked:bg-red flex items-center justify-center">
                                            <svg
                                                className="hidden"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="10.763"
                                                height="8.5"
                                                viewBox="0 0 10.763 8.5"
                                            >
                                                <path
                                                    d="M1051.851-12983l2.969,2.969,6.379-6.379"
                                                    transform="translate(-1051.144 12987.117)"
                                                    fill="none"
                                                    stroke="#18b573"
                                                    strokeWidth="2"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className="px-4 py-3 bg-green uppercase text-white text-sm font-semibold rounded-md">
                        Search
                    </button>
                </article>
            </form>
        </div>
    );
};

export default HomepageFilterSearch;
